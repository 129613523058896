import React from 'react';
import '../styles/index.scss';

import Header from '../components/header';
import Vision from '../sections/vision';
// import About from '../sections/about';
import Footer from '../sections/footer';
import Seo from '../components/seo';

// Contexts
import LanguageProvider from '../contexts/language';
import {NavContextProvider} from '../contexts/navigation';

function AboutPage(props) {
    return (
        <div>
            <LanguageProvider>
                <NavContextProvider>
                    <Seo title="Our Vision"/>
                    <Header/>
                    <Vision/>
                    <Footer/>
                </NavContextProvider>
            </LanguageProvider>
        </div>
    );
}

export default AboutPage;